function sjData(rs) {
	if (rs == null) {
		console.log("Error: Unexpected - ors data is null in function sjRecordset(ors)");
		return
	}

	//this.rawData = rs;
	let f = rs[0], d = rs[1];

	this.fields = f;
	this.records = d;

	let arData = new Array(d.length);
	for (let row = 0; row < d.length; row++) {
		arData[row] = {};
		for (let t = 0; t < f.length; t++) {
			arData[row][f[t]] = d[row][t];
		}
		arData[row]['_rowNum'] = row;
	}
	this.data = arData; //Set sjData.data to data object
}

export default sjData

