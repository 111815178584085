<template v-if="isLoggedIn">
    <div class="container page">

        <div class="card card-default" style="min-width:420px">
            <div class="card-header"><h3>Card Statement</h3></div>
            <div class="card-body">
                <div class="row mb-2">
                    <div class="col-md-3 fldName">Card Number:</div>
                    <div class="col-md-9 fldValue">{{ cardNum }}</div>
                </div>
                <div class="row mb-2">
                    <div class="col-md-3 fldName">Your Balance:</div>
                    <div class="col-md-9 fldValue">{{ balance }}</div>
                </div>
                <div class="row mb-2">
                    <div class="col-md-3 fldName">Card Expiry:</div>
                    <div class="col-md-9 fldValue">{{ expiry }}</div>
                </div>
                <hr />

                <table class="table table-hover table-condensed">
                    <tr><th>Date</th><th>Description</th><th class="text-right">Debit</th><th class="text-right">Credit</th></tr>
                    <tr v-for="f in report" :key="f._rowNum">
                        <td>{{ f.transactionDate.sjToDate('d MMM yyyy HH:mm') }}</td>
                        <td>{{ f.transactionDescription.sjReplaceAll('PLP', '') }}</td>
                        <td class="text-right">{{ f.transactionType==2 ? (f.transactionAmount / 100).sjFormatMoney() : '' }}</td>
                        <td class="text-right">{{ f.transactionType==1 ? (f.transactionAmount / 100).sjFormatMoney() : '' }}</td>
                    </tr>
                </table>
                <div v-if="isLoading"><i>Loading...</i></div>


                <div><b><label ID="lblMessage" runat="server" Text="" ForeColor="#ff0000" /></b></div>
            </div>
        </div>



    </div>

   
</template>

<script>
    // @ is an alias to /src
    import axios from 'axios'
    //import sjData from '@/sjUtilData'


    export default {
		name: 'Statement',
        data() {
            return {
                cardNum: '',
                balance: '',
                expiry: '',
                date: '',
                report: [],
                isLoading: false
            }
        },
        computed: {
            member() {
                return this.$store.state.memberStore.member
            },

            isLoggedIn() {
                return this.$store.state.isLoggedIn
            },
        },

        created() {
            this.loadReport();
        },
        mounted() {
            //axios.post('/api/member/memberget', {})
            //	.then(({ data }) => {
            //		//this.roles = data.roles;
            //		//this.noms = new sjData(data.noms).data;
            //		//this.displayPart = 1;
            //	})
            //	.catch((error) => {
            //		if (error) {
            //			this.msgBox(error.response.data.message);
            //		}
            //	})
        },
		watch: {
			
		},

        methods: {
            loadReport() {
                this.isLoading = true;
                this.report = [];
				axios.post('/api/giftcardtk/statement', {  })
                    .then(({ data }) => {
                        this.isLoading = false;
                        this.cardNum = data.cardNum;
                        this.balance = (data.balanceInCents / 100).sjFormatMoney();
                        this.expiry = data.expiry;
                        this.report = data.statement;
                    })
                    .catch((error) => {
						this.isLoading = false;
                        if (error) {
                            this.msgBox(error.response.data.message);
                        }
                    })
            },


            //*** formStatusUpdate - used to display form errors.
            //Ensure this.form contains the list of fields.
            //Call  formStatusUpdate(true) to initialise/clear the form errors.
            //formObject is the object that contains the following objects:  .form, .formState, .formErrors
            formSetState(formObj, resetState, resetForm) {
                if (typeof resetState === 'undefined') resetState = false;
                if (typeof resetForm === 'undefined') resetForm = false;
                if (typeof formObj.init === 'undefined') formObj.init = { ...formObj.form }; //Initialise .formInit object to be a copy of .form object.
                if (typeof formObj.errors === 'undefined') this.$set(formObj, 'errors', {}); //use this.$set  to make errors object reactive
                if (typeof formObj.state === 'undefined') this.$set(formObj, 'state', {});   //use this.$set  to make state object reactive

                if (resetState || resetForm) {
                    if (resetForm) formObj.form = { ...formObj.init }; //copy .init to .form

                    formObj.errors = {}; //reset formErrors object
                    formObj.state = {};
                }

                //update formState
                var fields = Object.getOwnPropertyNames(formObj.form);
                for (let i = 0; i < fields.length; i++) {
                    let field = fields[i];
                    if (resetState) {
                        formObj.state[field] = null;
                    } else if (typeof formObj.errors[field] === 'undefined' || formObj.errors[field] === '') {
                        formObj.state[field] = true;
                    } else {
                        formObj.state[field] = false;
                    }
                }
            },
            msgBox(msg, title) {
                this.$bvModal.msgBoxOk(msg, { title: typeof title === 'undefined' ? 'Please note:' : title, centered: true })
            }


        }

    }
</script>
