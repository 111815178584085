<template v-if="isLoggedIn">
    <div class="container page">

        <div class="card card-default">
            <div class="card-header"><h3>Recent Orders</h3></div>
            <div class="card-body">

                <section v-for="order in orders" :key="order._rowNum">
                    <div class="row mb-3">
                        <div class="col-sm-6"><h4 class="subtitle">Order: {{ order.orderNum }}</h4></div>
                        <div class="col-sm-6 text-right"><h4 class="subtitle">{{ order.orderDate.sjToDate('d MMM yyyy HH:mm') }}</h4></div>
                    </div>
                    <div class="row mb-1">
                        <div class="col-sm-2 fldName">Status:</div>
                        <div class="col-sm-10 fldValue">{{ order.orderStatusDesc }}</div>
                    </div>
                    <div class="row mb-1">
                        <div class="col-sm-2 fldName">Details:</div>
                        <div class="col-sm-10">
                            <table cellspacing="1" cellpadding="1">
                                <tbody v-for="detail in order.orderDetails" :key="detail._rowNum">
                                    <tr><td>&nbsp;</td></tr>
                                    <tr><td colspan="2">{{ detail.productCode + ' - ' + detail.itemDescription }}</td></tr>
                                    <tr><td colspan="2">{{ detail.itemQty + '&nbsp;@&nbsp;' + formatMoney(detail.itemPrice) + '&nbsp;ea&nbsp;&nbsp;=&nbsp;&nbsp;' + formatMoney(detail.itemTotal) }}</td></tr>
                                    <tr v-if="detail.dispatchStatus===4 && order.orderStatusId.sjRemoveIntHash()==='30'">
                                        <td colspan="2"><a href="#" @click.prevent="loadVoucherCodes(detail.memberOrderItemId)">View Voucher Codes</a></td>
                                    </tr>
                                </tbody>
                            </table>
                        </div>
                    </div>
                    <div class="row mb-1">
                        <div class="col-sm-2 fldName">Sub Total:</div>
                        <div class="col-sm-10 fldValue">{{ formatMoney(order.totalGrandTotal) }}<i> ({{ order.itemCount + ' item' + (order.itemCount === 1 ? '' : 's') }})</i></div>
                    </div>
                    <div v-if="order.totalDiscount !== 0" class="row mb-1">
                        <div class="col-sm-2 fldName">Discount:</div>
                        <div class="col-sm-10 fldValue">{{ formatMoney(order.totalDiscount) }}<i> (Promo Code: {{ order.VoucherCode }})</i></div>
                    </div>
                    <div class="row mb-1">
                        <div class="col-sm-2 fldName">Total Due:</div>
                        <div class="col-sm-10 fldValue font-weight-bold">{{ formatMoney(order.totalDue) }}</div>
                    </div>
                    <hr />
                </section>
                <div v-if="isLoading"><i>Loading...</i></div>
                <div v-if="orders.length===0"><i>You have no recent orders.</i></div>
                

                <div><b><label ID="lblMessage" runat="server" Text="" ForeColor="#ff0000" /></b></div>
            </div>
        </div>



    </div>

   
</template>

<script>
    // @ is an alias to /src
    import axios from 'axios'
    import sjData from '@/sjUtilData'


    export default {
        name: 'Statement',
        data() {
            return {
                monthList: [],
                selMonth: null,
                isAll: false,
                orders: [],
                isLoading: false,
				inf: { productCurrency: "", currencySymbol: "", currencyDecimalPlaces: 0, isTruncateCents: true, isCurrencySymbolSuffix: true },
            }
        },
        computed: {
            member() {
                return this.$store.state.memberStore.member
            },

            isLoggedIn() {
                return this.$store.state.isLoggedIn
            },
        },

        created() {
            this.loadReport();
        },
        mounted() {

        },
        watch: {

        },

        methods: {
            loadReport() {
                this.isLoading = true;
                this.report = [];
                axios.post('/api/shop/orders', {})
                    .then(({ data }) => {
                        this.isLoading = false;
                        let orders = new sjData(data.orders).data;
                        let orderDetails = new sjData(data.orderDetails).data;
                        for (const order of orders) {
							order.orderDetails = orderDetails.filter(rec => rec.memberOrderId == order.memberOrderId); //append owners array to each idea
                        }
                        this.orders = orders;
						this.inf = data.inf;
                    })
                    .catch((error) => {
                        this.isLoading = false;
                        if (error) {
                            this.msgBox(error.response.data.message);
                        }
                    })
            },

			loadVoucherCodes(memberOrderItemId) {
				axios.post('/api/shop/getVoucherCodeMessage', {'memberOrderItemId' : memberOrderItemId})
                    .then(({ data }) => {

						const h = this.$createElement;
						const msg = h('div', { domProps: { innerHTML: data.message } });

                        this.msgBox(msg, "Voucher Details:");
					})
					.catch((error) => {
						if (error) {
							this.msgBox(error.response.data.message);
						}
					})
			},

            msgBox(msg, title) {
                this.$bvModal.msgBoxOk(msg, { title: typeof title === 'undefined' ? 'Please note:' : title, centered: true })
            },


			realTypeOf: function (obj) {
				//Returns the type of an object. i.e. String,Boolean,Date,Number, etc  http://mrrena.blogspot.com/2012/05/javascript-better-typeof-accurately.html
				return Object.prototype.toString.call(obj).slice(8, -1);
			},
			formatMoney: function (value) {
				//format = "R|2|false|.|
				const p = this.inf;
				return (this.realTypeOf(value) == 'Number') ? value.sjFormatMoney(p.currencySymbol + '|' + (p.isTruncateCents ? 0 : p.currencyDecimalPlaces) + '|' + p.isCurrencySymbolSuffix + "|.") : value;
			},
			formatMoneyInclCents: function (value) {
				//format = "R|2|false|.|
				const p = this.inf;
				return (this.realTypeOf(value) == 'Number') ? value.sjFormatMoney(p.currencySymbol + '|' + (p.isTruncateCents ? 0 : p.currencyDecimalPlaces) + '|' + p.isCurrencySymbolSuffix + "|.") : value;
			},


        }

    }
</script>
