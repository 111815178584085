<template>
    <footer class="pagefooter" :style="isLoginPage?'':'margin-top:50px'">
        <div class="container-fluid clearfix d-print-none">
            <div class="row">
                <div class="col-sm-2 d-flex align-items-center justify-content-center justify-content-sm-start">
                    <a href="/"><img src="../assets/image/footerLogo.png" class="img-fluid" /></a>
                </div>
                <div class="col-sm-8 my-2 d-flex align-items-center justify-content-center">
                    <section class="text-center">
                        <!--<template v-if="isLoggedIn">
        | <router-link to="/howitworks" class="hvrlinkbutton mx-2">How&nbsp;It&nbsp;Works</router-link>
    </template>-->
                        <div>
                            <a href="#" class="hvrlinkbutton mx-2" @click.prevent="$bvModal.show('modalCookiePolicyPreferences')">Manage&nbsp;Privacy</a>
                            | <router-link to="/privacy" class="hvrlinkbutton mx-2">Privacy&nbsp;Policy</router-link>
                            | <router-link to="/terms" class="hvrlinkbutton mx-2">Terms&nbsp;and&nbsp;Conditions</router-link>
                            | <router-link to="/disclaimer" class="hvrlinkbutton mx-2">Disclaimer</router-link>
                            <template v-if="isLoggedIn">
                                |
                                <router-link to="/contactus" class="hvrlinkbutton mx-2">Contact Us</router-link>
                            </template>
                        </div>
                        <div class="mt-1">
                            <span class="mx-2">Copyright &copy; {{ new Date().getFullYear() }} Siemens Rewards</span>
                        </div>
                        <!--<template v-if="isLoggedIn">
        | <router-link to="/newsletters" class="hvrlinkbutton mx-2">Newsletters</router-link>
    </template>-->
                    </section>
                </div>
                <div class="col-sm-2  d-flex align-items-center justify-content-center justify-content-sm-end">
                    <a href="https://www.therewardsfactory.co.za/" target="_blank"><img src="../assets/image/footerPoweredBy.png" class="img-fluid" /></a>
                </div>
            </div>
        </div>

        <!--<img src="../assets/image/footer.png" class="img-fluid d-none d-lg-block" />
    <img src="../assets/image/footerMobi.png" class="img-fluid d-lg-none" />-->
    </footer>
</template>

<script>
//import { mapState } from 'vuex'
    export default {
        name: 'AppFooter',
        components: {
        },
        computed: {
			isLoggedIn() {
				return this.$store.state.isLoggedIn
			},
            rootFolder() {
                return process.env.BASE_URL
            },
            isLoginPage() {
                return this.$route.name === "Hello";
			}
        }
    }
</script>

