export default {
    //document.cookie = "My_Cookie=; expires=Thu, 01 Jan 2000 00:00:00 UTC; path=/;"; //Delete cookie,
    setCookie: function (cname, cvalue, exdays) { //if exDays not specified then 
        let expires = "";
        if (exdays) {
            const d = new Date();
            d.setTime(d.getTime() + (exdays * 24 * 60 * 60 * 1000));
            expires = "expires=" + d.toUTCString() + ";";
        }
        document.cookie = cname + "=" + cvalue + ";" + expires + "path=/";
    },

    getCookie: function (cname) {
        var name = cname + "=";
        var decodedCookie = decodeURIComponent(document.cookie);
        var ca = decodedCookie.split(';');
        for (var i = 0; i < ca.length; i++) {
            var c = ca[i];
            while (c.charAt(0) == ' ') {
                c = c.substring(1);
            }
            if (c.indexOf(name) == 0) {
                return c.substring(name.length, c.length);
            }
        }
        return "";
    },

    includeJs: function (src, type) { //includeJs("/path/to/some/file.js");
        var js = document.createElement("script");
        js.type = type ? type : "text/javascript";
        js.src = src;
        document.body.appendChild(js);
    },

    includeJsCode: function (code, type) { //includeJs("/path/to/some/file.js");
        var js = document.createElement("script");
        js.type = type ? type : "text/javascript";
        js.innerHTML = code;
        document.body.appendChild(js);
    }
}


