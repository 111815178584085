<template>
    <div>
        <div class="form-group row">
            <label v-if="isShowCountry" class="pl-xl-5 col-lg-2 col-form-label" for="country">Country:</label>
            <div v-show="isShowCountry" class="col-lg-6"><b-form-select v-model="selectedCountryCode" :options="countryList" value-field="codeId" text-field="description" v-on:change="resetCompany($event)"></b-form-select></div>
        </div>
        <div class="form-group row">
            <label v-if="isShowCompany" class="pl-xl-5 col-lg-2 col-form-label" for="division">Company:</label>
            <div v-show="isShowCompany" class="col-lg-6"><b-form-select v-model="selectedCompanyCode" :options="companyList" value-field="codeId" text-field="description" v-on:change="resetDivision($event)"></b-form-select></div>
        </div>
        <div class="form-group row">
            <label v-if="isShowDivision" class="pl-xl-5 col-lg-2 col-form-label" for="division">Division:</label>
            <div v-show="isShowDivision" class="col-lg-6"><b-form-select v-model="selectedDivisionCode" :options="divisionList" value-field="codeId" text-field="description"></b-form-select></div>
        </div>
    </div>
</template>

<script>


    export default {
        name: 'MemberDivisionSelect',
        components: {
        },
        data() {
            return {
                selectedCountryCode: null,
                selectedCompanyCode: null,
                selectedDivisionCode: null,
                isShowCountry: true,
                isShowCompany: true,
                isShowDivision: true,
                isLoading: false,
				isCountryFixed: false,
				isCompanyFixed: false,
				isDivisionFixed: false
            }
        },
        props: {
            defaultCountryCode: {
                type: String, required: false, default: null
            },
			defaultCompanyCode: {
				type: String, required: false, default: null
			},
            defaultDivisionCode: {
                type: String, required: false, default: null
            },
           
        },
        computed: {

            countryList() {
				if (this.isCountryFixed || !this.isShowCountry || this.$store.state.countryList.length == 1 ) { //only show the default cluster.
                    return this.$store.state.countryList.filter(country => country.codeId === this.defaultCountryCode);
                } else {
                    return [{ codeId: null, description: '-- Please select a Country --', disabled: true }]
                        .concat(this.$store.state.countryList);
                }
            },

			companyList() {
				if (this.isCompanyFixed || !this.isShowCompany || this.$store.state.companyList.length == 1) { //only show the default cluster.
					return this.$store.state.companyList.filter(company => company.codeId === this.defaultCompanyCode);
				} else {
					return [{ codeId: null, description: '-- Please select a Company--', disabled: true }]
						.concat(this.$store.state.companyList.filter(rec => rec.parentCodeId === this.selectedCountryCode));
				}
			},

            divisionList() {
				if (this.isDivisionFixed || !this.isShowDivision || this.$store.state.divisionList.length == 1) { //only show the default cluster.
					return this.$store.state.divisionList.filter(division => division.codeId === this.defaultDivisionCode);

                //if (!this.isShowDivision) {
				//	return [{ codeId: null, description: '--All Divisions--', disabled: false }];
                } else {
					return [{ codeId: null, description: '-- Please select a Division--', disabled: false }]
                        .concat(this.$store.state.divisionList.filter(rec => rec.parentCodeId === this.selectedCompanyCode));
                }
            },

            selectedDivisionName() {
                if (typeof this === undefined || this.selectedDivisionCode == null) return '';
                let a = this.$store.state.divisionList.filter(
                    rec => {
                        return rec.codeId === this.selectedDivisionCode
                    }
                );
                return a[0].description;
            }
        },
		watch: {
			selectedDivisionCode() {  //newDivisionCode, oldDivisionCode - called when divisionCode changes
				this.onDivisionChanged();
			},
		},
		created() {
			this.selectedCountryCode = this.defaultCountryCode;
			this.selectedCompanyCode = this.defaultCompanyCode;
			this.selectedDivisionCode = this.isShowDivision ? this.defaultDivisionCode : null;
		},
        methods: {
			resetCompany() { //Called when Country dropdown changes
                this.selectedCompanyCode = null;
                this.resetDivision();
			},
			resetDivision() {//Called when Company dropdown changes
                this.selectedDivisionCode = null;
            },
			onDivisionChanged() {
                this.$emit('change', {
                    countryCode: this.selectedCountryCode,
                    companyCode: this.selectedCompanyCode,
                    divisionCode: this.selectedDivisionCode,
                    divisionName: this.selectedDivisionName
                });
			}
        },


    }
</script>

