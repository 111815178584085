<template>
    <div v-if="isLoggedIn" class="container page">

        <section v-if="displayPart==0" class="card card-default">
            <div class="card-header">
                <h3>Recognise</h3>
            </div>
            <div class="card-body">
                <div class="row">
                    <div class="col-sm-6"><strong>Recipients:</strong></div>
                    <div class="col-sm-6 text-right"></div>
                </div>
                <div v-for="r in selectedMems" :key="r._rowNum" class="row">
                    <template v-if="r.isEntireDivision">
                        <div class="col-1 text-right"><b-icon icon="collection" style="color:#9f34a0"></b-icon></div>
                        <div class="col" style="color:#9f34a0">Entire division: {{ r.division }}</div>
                    </template>
                    <template v-else>
                        <div class="col-1 text-right"><b-icon icon="person" style="color:#ccc"></b-icon></div>
                        <div class="col">{{ r.firstname + ' ' + r.surname }}</div>
                        <div class="col">{{ r.division }}</div>
                    </template>
                </div>
                <div v-if="selectedMems.length===0" class="text-danger">Please select a recipient.</div>
                <div class="mt-1">
                    <b-button type="button" pill @click="$bvModal.show('modalChooseNominees')" variant="primary" size="sm" class="mt-2">{{selectedMems.length===0 ? 'Select' : 'Change'}}  Recipient</b-button>
                    <!--<a href="#" @click.prevent="$bvModal.show('modalChooseNominees')">Select Nominees</a>-->
                </div>

                <hr />
                <b-form @submit.prevent="onSubmitReward" novalidate>
                    <b-form-group id="groupReason" label-for="selectreason" label="Value:" :invalid-feedback="fReward.errors.reason" description="">
                        <b-form-select id="selectreason" v-model="fReward.form.reason" :state="fReward.state.reason">
                            <b-form-select-option :value="null">-- Select a Value --</b-form-select-option>
                            <b-form-select-option value="Living the Values">Living the Values</b-form-select-option>
                            <b-form-select-option value="Doing What Matters">Doing What Matters</b-form-select-option>
                            <b-form-select-option value="Taking the Lead">Taking the Lead</b-form-select-option>
                            <b-form-select-option value="Making People Matter">Making People Matter</b-form-select-option>
                        </b-form-select>
                    </b-form-group>

                    <b-form-group id="groupSendBy" label-for="sendBy" description="" :invalid-feedback="fReward.errors.sendBy" :state="fReward.state.sendBy" class="mt-4">
                        <b-form-radio-group id="radiogroupSendBy" v-model="fReward.form.sendBy" name="sendBy" :state="fReward.state.sendBy">
                            <div class="row">
                                <div class="col-sm-3 col-lg-2">
                                    <b-form-radio value="email">Email</b-form-radio>
                                </div>
                                <div class="col-sm-3 col-lg-2">
                                    <b-form-radio value="sms">Email and SMS</b-form-radio>
                                </div>
                            </div>
                        </b-form-radio-group>
                    </b-form-group>

                    <b-form-group id="groupRewardMessage" label-for="txtRewardMessage" label="Message to Recipients" :invalid-feedback="fReward.errors.message" :description="(isSendBySMS?150:1000)-fReward.form.message.length + ' characters remaining'">
                        <b-form-textarea id="txtRewardMessage"  :disabled="fReward.form.sendBy===null" v-model="fReward.form.message" :state="fReward.state.message" :placeholder="isSendBySMS ? 'Short SMS message to recipients up to 150 characters' : 'Short email message to recipients up to 1000 characters'" rows="3" max-rows="5" :maxlength="(isSendBySMS?150:1000)" />
                    </b-form-group>

                    <hr />

                    <div class="text-right"><b-button type="submit" variant="primary">Submit</b-button></div>
                </b-form>
            </div>
        </section>

        <section v-if="displayPart==1" class="card card-default">
            <div class="card-header">
                <h3>Recognise</h3>
            </div>
            <div class="card-body">
                <b-alert variant="success" show>
                    <h4 class="alert-heading">Recognise</h4>
                    <p>
                        Successfully submitted Recognition.
                    </p>
                </b-alert>
            </div>
            <div class="card-footer">
                <div class="text-right"><button type="button" @click="displayPart=0" class="btn btn-primary">Send Another</button></div>
            </div>

            
        </section>



        <!--
    **************************************************************************************************************************************************
                                                                M O D A L S
    **************************************************************************************************************************************************
    -->
        <!-- Modal - Nominees -->
        <b-modal id="modalChooseNominees" hide-footer size="xl" no-close-on-backdrop title="Shout Out">
            <member-select title="Select recipients"
                           selectedTitle="Recipients"
                           :defaultCountryCode="memberCountryCode"
                           :defaultCompanyCode="memberCompanyCode"
                           :defaultDivisionCode="memberDivisionCode"
                           :selectedMems="selectedMems"
                           :isAllowWholeDivision="false"
                           :isSingle="false"
                           :showContinue="false"
                           class="mb-3" />

            <hr />
            <div class="text-right"><b-button type="submit" variant="primary" @click="$bvModal.hide('modalChooseNominees')">Done</b-button></div>
        </b-modal>

    </div>

</template>

<script>
    // @ is an alias to /src
    import axios from 'axios'
    //import sjData from '@/sjUtilData'
    import MemberSelect from '@/components/MemberSelect';


    export default {
        name: 'Recognise',
        components: {
            MemberSelect
        },
        data() {
            return {
                displayPart: 0,

                fReward: {
					form: { what:'thankyou', reason: null, points: 0, message: '', sendBy: null, recipients: [] }
                },

                selectedMems: [],

                roles: { ca: false, cc: false, idea: false, inv: false },

                reportTitle: '',

            }
        },
        computed: {
            isLoggedIn() {
                return this.$store.state.isLoggedIn
            },
			memberCountryCode() {
				return this.$store.state.memberStore.member.countryCode
			},
            memberCompanyCode() {
				return this.$store.state.memberStore.member.companyCode
            },
            memberDivisionCode() {
                return this.$store.state.memberStore.member.divisionCode
            },
            isSendBySMS() {
                return this.fReward.form.sendBy === 'sms';
			}
        },
        created() {
            //formSetState must be called on creation, to ensure all fields are setup
            this.formSetState(this.fReward, true, true) //formObj, resetState, resetForm

			this.preSelectMemberLinkIn();

            this.$store
                .dispatch('getDivisionList', {})
                .then(() => {
                    this.displayPart = 0;

                });
        },
        mounted() {

        },
        methods: {
            msgBox(msg, title) {
                this.$bvModal.msgBoxOk(msg, { title: typeof title === 'undefined' ? 'Please note:' : title, centered: true })
            },

            //*** formStatusUpdate - used to display form errors.
            //Ensure this.form contains the list of fields.
            //Call  formStatusUpdate(true) to initialise/clear the form errors.
            //formObject is the object that contains the following objects:  .form, .formState, .formErrors
            formSetState(formObj, resetState, resetForm) {
                if (typeof resetState === 'undefined') resetState = false;
                if (typeof resetForm === 'undefined') resetForm = false;
                if (typeof formObj.init === 'undefined') formObj.init = { ...formObj.form }; //Initialise .formInit object to be a copy of .form object.
                if (typeof formObj.errors === 'undefined') this.$set(formObj, 'errors', {}); //use this.$set  to make errors object reactive
                if (typeof formObj.state === 'undefined') this.$set(formObj, 'state', {});   //use this.$set  to make state object reactive

                if (resetState || resetForm) {
                    if (resetForm) formObj.form = { ...formObj.init }; //copy .init to .form

                    formObj.errors = {}; //reset formErrors object
                    formObj.state = {};
                }

                //update formState
                var fields = Object.getOwnPropertyNames(formObj.form);
                for (let i = 0; i < fields.length; i++) {
                    let field = fields[i];
                    if (resetState) {
                        formObj.state[field] = null;
                    } else if (typeof formObj.errors[field] === 'undefined' || formObj.errors[field] === '') {
                        formObj.state[field] = true;
                    } else {
                        formObj.state[field] = false;
                    }
                }
            },

			preSelectMemberLinkIn() {
				//Add member to selected list, if passed in on sessionStorage (tloid)
				let tloidMemberId = sessionStorage.getItem('tloid'); //tloid - temp link out id - i.e. MemberId
				if (tloidMemberId) {
					sessionStorage.removeItem('tloid');
					axios.post('/api/site/getmemberget', { memberId: tloidMemberId })
						.then(({ data }) => {
							this.selectedMems.push({
								_serialNo: -1,
								division: data.companyName,
								firstname: data.firstName,
								surname: data.surname,
								isManager: false,
								memberId: data.memberId,
								memberNumber: data.memberNumber,
								isEntireDivision: false,
								entireDivisionCode: ''
							});
						})
						
				}

				let tlowhat = sessionStorage.getItem('tlowhat'); //tloid - temp link out id - i.e. MemberId
				if (tlowhat) {
					sessionStorage.removeItem('tlowhat');

					if (tlowhat == "BDAY") {
						this.fReward.form.reason = 'birthday';
					} else if (tlowhat == "ANNIV") {
						this.fReward.form.reason = 'anniversary';
					}
				}
			},

            onSubmitReward() {
                this.formSetState(this.fReward, true);//formObj, resetState, resetForm
				this.fReward.form.recipients = this.selectedMems.map(item => item.isEntireDivision ? 'div:' + item.entireDivisionCode : item.memberId);
				if (this.selectedMems.length === 0) {
					this.msgBox('Please select at least one recipient.','Recipients required');
					return;
                } 



                //this.displayPart = 1;
                if (this.displayPart == 0) {
                    return axios
                        .post(
                            '/api/reward/doreward',
                            this.fReward.form
                        )
                        .then(({ data }) => {
                            console.log(data);
                            this.displayPart = 1;
							this.selectedMems = [];
                            this.formSetState(this.fReward, true, true);
							//this.$store.dispatch('memberStore/getMemberData');
                        })
                        .catch((error) => {
                            this.msgBox(error.response.data.message ? error.response.data.message : ('An error has occurred: ' + JSON.stringify(error.response.data)));
                            if (error.response.data.formErrors) {
                                this.fReward.errors = error.response.data.formErrors;
                                this.formSetState(this.fReward);
                            }
                        })
                        ;
                }
            },



        }




    }
</script>
