import axios from 'axios'
import Vue from 'vue'
import Vuex from 'vuex'

import memberStore from '@/store/modules/memberStore.js'
import sjData from '@/sjUtilData'

Vue.use(Vuex)

export default new Vuex.Store({
    modules: {
        memberStore
    },
    state: {
        isLoggedIn: false,
        siteTemplate: '',
        siteTemplateComponent: 'AppNoTemplate',

        windowWidth: 0,
        windowHeight: 0,

        dataLoadingCount: 0,

        countryList: [], 
        companyList: [], 
        divisionList: [],

        isDivisionsLoaded: false,
    },
    getters: {
        isDoPopulateDivisionList: state => () => {
            return (state.countryList.length === 0 && state.companyList.length === 0 && state.divisionList.length === 0);
        }
    },
    actions: {
        setDataLoading({ commit }, isLoading) {
            commit('DATA_LOADING', isLoading)
        },

        setWindowResized({ commit }, dimensions) {
            commit('WINDOW_RESIZED', dimensions)
        },

        logout({ commit }) {
            commit('memberStore/LOGOUT')
        },

        getDivisionList({ commit, getters }) {
            if (getters.isDoPopulateDivisionList) {
                return axios.post('/api/site/getdivisionlist')
                    .then(({ data }) => {
                        let divisionData = {
                            countryList: new sjData(data.countryList).data,
                            companyList: new sjData(data.companyList).data,
                            divisionList: new sjData(data.divisionList).data,
                        }

                        commit('SET_DIVISION_LIST', divisionData);
                    })
            }
        }

    },
    mutations: {
        WINDOW_RESIZED(state, dimensions) {
            state.windowWidth = dimensions.windowWidth;
            state.windowHeight = dimensions.windowHeight;
        },
        DATA_LOADING(state, isloading) {
            if (isloading) {
                state.dataLoadingCount++;
            } else if (state.dataLoadingCount > 0) {
                state.dataLoadingCount--;
            }
        },
        SET_LOGIN_DATA(state, data) { //called from MemberStore
            let siteTemplateComponent = 'AppNoTemplate'
            let siteTemplate = ''
            let loginData = { isLoggedIn: false, siteTemplate: '', siteTemplateComponent: '' }
            let isValid = typeof data.memberData !== 'undefined' && data.memberData.siteTemplate !== '';
            if (isValid) {
                siteTemplateComponent = 'AppNoTemplate';
                loginData = { isLoggedIn: isValid, siteTemplate, siteTemplateComponent };
            }
            state.isLoggedIn = loginData.isLoggedIn;
            state.siteTemplate = loginData.siteTemplate;
            state.siteTemplateComponent = loginData.siteTemplateComponent;
            sessionStorage.setItem('site', siteTemplate);
        },

        CLEAR_LOGIN_DATA(state) {
            state.isLoggedIn = false;
            state.siteTemplate = '';
            state.siteTemplateComponent = 'AppNoTemplate';
            sessionStorage.removeItem('SecBr');
            axios.defaults.headers.common['Authorization'] = '';
        },

        SET_DIVISION_LIST(state, data) {
            state.countryList = data.countryList;
            state.companyList = data.companyList;
            state.divisionList = data.divisionList;
            state.isDivisionsLoaded = true;
        },
    }
})
