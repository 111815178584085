<template>
    

    <section v-if="isLoggedIn" class="container-fluid homePg mt-5">
        <div class="mb-3"><!-- <sdj:AdRotator RotatorType="TopAd" runat="server" HideSlideDots="true" /> --></div>
        <div class="row">
            <div class="col-lg-4 mb-3">
                <div ref="sizecalc1">
                    <div><router-link to="/celebrations" class="hvrlinkbutton"><img src="../assets/image/homeCelebrations.jpg" class="w-100" /></router-link></div>
                    <div><img src="../assets/image/homeSpacerLeft.png" class="img-fluid" /></div>
                    <!--<div><router-link to="/walloffame" class="hvrlinkbutton"><img src="../assets/image/homeWall.jpg" class="w-100" /></router-link></div>-->
                    <div><router-link to="/myrecognition" class="hvrlinkbutton"><img src="../assets/image/homeMyRecognition.jpg" class="w-100" /></router-link></div>
                </div>
            </div>
            <div class="col-lg-4 mb-3">

                <div class="homeShoutout d-flex flex-column" ref="homeShoutout">
                    <div class="mb-3 homeShoutOutImg px-3 py-3"><img src="../assets/image/homeShoutOuts.png" class="w-100" /></div>

                    <div class="shoutouts align-self-stretch" id="divShoutOutData">
                        <div v-for="item in recentRecognition" :key="item._rowNum" class="row shoutoutitem no-gutters">
                            <div class="col-xl-2 d-flex align-items-center justify-content-center"><img :src="('/profilepic/' + item.toMemberId)" /></div>
                            <div class="col-xl-10 pl-xl-3">
                                <div class="row pb-2">
                                    <div class="col-xl-2 shoutlabel"><b>From:</b></div>
                                    <div class="col-xl-10 shoutfrom">{{ item.fromMemberName }}</div>
                                </div>
                                <div class="row pb-2">
                                    <div class="col-xl-2 shoutlabel"><b>To:</b></div>
                                    <div class="col-xl-10 shoutto">{{ item.memberName }}</div>
                                </div>
                                <div class="row">
                                    <div class="col-xl-2 shoutlabel"><b>For:</b></div>
                                    <div class="col-xl-10 shoutfor">{{ item.value }}</div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <!--<div class="text-center my-3"><router-link to="/recentrecognition" class="btn btn-secondary btn-sm">View More</router-link></div>-->
                </div>

                

            </div>
            <div class="col-lg-4 mb-3">
                <div ref="homeRightCol">
                    <div class="homeRightButtons px-3 py-3 py-xl-4"><router-link to="/recognise"><img src="../assets/image/homeRecognise.png" class="w-100" /></router-link></div>
                    <div><img src="../assets/image/homeSpacerRight.png" class="img-fluid" /></div>
                    <div class="homeRightButtons px-3 py-3 py-xl-4"><router-link to="/rewards"><img src="../assets/image/homeRedeem.png" class="w-100" /></router-link></div>
                    <div><img src="../assets/image/homeSpacerRight.png" class="img-fluid" /></div>
                    <div class="homeRightButtons px-3 py-3 py-xl-4">
                        <router-link v-if="isManager" to="/reward"><img src="../assets/image/homeReward.png" class="w-100" /></router-link>
                        <router-link v-else to="/statement"><img src="../assets/image/homeRewardsStatement.png" class="w-100" /></router-link>
                    </div>
                    <div><img src="../assets/image/homeSpacerRight.png" class="img-fluid" /></div>
                </div>
                <div class="homeGetToKnow">
                    <div ref="homeGetToKnowTitle" class="homeRightButtons p-3"><router-link to="/gettoknow"><img src="../assets/image/homeGetToKnow.png" class="w-100" /></router-link></div>

                        <b-carousel id="homeGtkCarousel" :interval="3700" controls img-width="1024" img-height="480">
                            <!--
        controls
        indicators
        v-model="slide"
        :interval="3700"
        background="#ababab"
        img-width="1024"
        img-height="480"
        style="text-shadow: 1px 1px 2px #333;"
        @sliding-start="onSlideStart"
        @sliding-end="onSlideEnd"
        -->
                            <template>
                                <b-carousel-slide v-for="item in getToKnow" :key="item._rowNum">
                                    <template v-slot:img>
                                        <!-- + (isActiveDone ? " : " active")     class="homeGtkSlide carousel-item" -->
                                        <div class="row no-gutters align-items-center homeGtkSlide">
                                            <div class="col-4 homeGtkPic">
                                                <div class="ml-3 mr-2">
                                                    <img class="w-100" :src="('/profilepic/' + item.memberId)" /><!-- + profileNum >-->
                                                </div>
                                            </div>
                                            <div class="col-8">
                                                <div class="ml-1 mr-2">
                                                    <div class="homeGtkName">{{ item.memberName }}</div>
                                                    <div class="homeGtkPos">{{ item.natureOfBusiness }}</div>
                                                    <div class="homeGtkData" v-if="item.interestOtherDesc"><b>Likes:</b> {{ item.interestOtherDesc }}</div>
                                                </div>
                                            </div>
                                        </div>
                                    </template>
                                </b-carousel-slide>
                            </template>
                        </b-carousel>
                        <!-- <div class="text-right homeGtkLink"><a href="gettoknow">MORE</a></div>-->
                </div>
            </div>

        </div>
        <!--
        <section class="row hellostats">
            <div class="col-4 col-lg-2 hellostatslg" id="divRewardsRedeemed">{{ rewardsRedeemed }}&nbsp;</div>
            <div class="col-8 col-lg-2 hellostats">Rewards <span class="statsbr"><br /></span>Redeemed</div>
            <div class="clearfix visible-sm-block"></div>
            <div class="col-4 col-lg-2 hellostatslg" id="divEcardsSent">{{ messagesSent }}&nbsp;</div>
            <div class="col-8 col-lg-2 hellostats">eCards <span class="statsbr"><br /></span>Sent</div>
            <div class="clearfix visible-sm-block"></div>
            <div class="col-4 col-lg-2 hellostatslg" id="divRewardsIssued">{{ rewardsIssued }}&nbsp;</div>
            <div class="col-8 col-lg-2 hellostats">Rewards <span class="statsbr"><br /></span>Issued</div>
        </section>
        -->
    </section>



    <!--<HelloWorld msg="Welcome to Your Vue.js App"/>-->
</template>

<script>
    // @ is an alias to /src
    //import HelloWorld from '@/components/HelloWorld.vue'
    //import SitecodeImage from '@/components/SitecodeImage';


    export default {
        name: 'Home',
        components: {}, //SitecodeImage
        computed: {
            isLoggedIn() {
                return this.$store.state.isLoggedIn
            },
            memberClusterCode() {
                return this.$store.state.memberStore.member.clusterCode
            },
            memberDivisionCode() {
                return this.$store.state.memberStore.member.divisionCode
            },
            isManager() {
                return this.$store.state.memberStore.member.isManager
            },
            recentRecognition() {
                return this.$store.state.memberStore.homeRecentRecognition
            },
            getToKnow() {
                return this.$store.state.memberStore.homeGetToKnow
            },
            celebrations() {
                return this.$store.state.memberStore.homeCelebrations
            },
            messagesSent() {
                return this.$store.state.memberStore.homeStats.messagesSent
            },
            rewardsIssued() {
                return this.$store.state.memberStore.homeStats.rewardsIssued
            },
            rewardsRedeemed() {
                return this.$store.state.memberStore.homeStats.rewardsRedeemed
            },
            windowWidth() {
                return this.$store.state.windowWidth
            }

        },
        mounted() {
            this.$nextTick(() => {
                //console.log('home.vue next tick mounted');
                this.handleResize();
                setTimeout(this.handleResize, 5000);
            });
        },
        updated() {
            this.$nextTick(() => {
                //console.log('home.vue next tick updated');
                this.handleResize();
                //setTimeout(this.handleResize, 5000);
            });
        },
        watch: {
            windowWidth() { //newWidth, oldWidth
                //console.log(`width changed to ${newWidth} from ${oldWidth}`);
                this.handleResize();
            }
        },
        methods: {
            handleResize: function () {
				if (!this.$refs.sizecalc1 || !this.$refs.sizecalc1.firstElementChild || !this.$refs.homeRightCol) {
					//console.log('handleResize: cannot find sizecalc1');
                    setTimeout(this.handleResize, 250)
                    return;
                }

                let h = this.$refs.sizecalc1.offsetHeight;
                let w = this.$refs.sizecalc1.offsetWidth;

				let hRightCol = this.$refs.homeRightCol.offsetHeight;
				let hGtkTitle = this.$refs.homeGetToKnowTitle.offsetHeight;

				if (w < 50 || h < 100 || hGtkTitle < 50 || hRightCol < 50) {
                    //console.log('handleResize: homeicon1 w or h = 0');
                    setTimeout(this.handleResize, 250)
                    return;
                }

                this.$refs.homeShoutout.style.height = h + "px";

                let hGtkSlide = (h - (hRightCol + hGtkTitle));
                var els = document.getElementsByClassName('homeGtkSlide');
                if (els) {
                    for (var i = 0; i < els.length; i++) {
                        els[i].style.height = hGtkSlide + "px"
                    };
                }
            }
        }
    }
</script>
