<template>
    <div>
        <div v-if="!isLoggedIn" class="container page">
            Click here to <router-link to="/contactsupport" class="hvrlinkbutton mx-2">Request Support</router-link>
        </div>
        <div v-if="isLoggedIn" class="container page">

            <section v-if="!isDone" class="card card-default">
                <div class="card-header">
                    <h4>Contact Us</h4>
                    <div><span ID="ContentDb2" ContentKey="topmsgcontactus" runat="server" /></div>
                </div>
                <div class="card-body">
                    <b-form @submit.prevent="onSubmit" novalidate>
                        <b-alert v-model="alertMessageVisible" :variant="alertVariant" dismissible>{{ alertMessage }}</b-alert>

                        <b-form-group id="groupContactName" label-for="txtContactName" label="Your Name:" :invalid-feedback="formErrors.ContactName" description="Your personal details">
                            <b-form-input id="txtContactName" v-model="form.ContactName" :state="formState.ContactName" type="text" placeholder="Enter your name" />
                        </b-form-group>

                        <b-form-group id="groupEmail" label-for="txtEmail" label="Email Address:" :invalid-feedback="formErrors.EmailAddress" description="Your email address">
                            <b-form-input id="txtEmail" v-model="form.EmailAddress" type="text" :state="formState.EmailAddress" placeholder="Enter your email address" />
                        </b-form-group>

                        <b-form-group id="groupMessage" label-for="txtMessage" label="Message:" :invalid-feedback="formErrors.Message" description="">
                            <b-form-textarea id="txtMessage" v-model="form.Message" :state="formState.Message" placeholder="Enter a short message" rows="10" max-rows="16" />
                        </b-form-group>

                        <div>
                            <b-form-checkbox id="chkAcceptTerms" switch v-model="form.AcceptTerms" :state="formState.AcceptTerms"><content-db contentKey="contactusterms" :isCache="true"></content-db></b-form-checkbox>
                        </div>

                        <div class="text-right"><b-button type="submit" variant="primary">Send Message</b-button></div>
                    </b-form>
                </div>
            </section>

            <section v-else class="card card-default">
                <div class="card-header">
                    <h4>Contact Us</h4>
                    <div><span ID="ContentDb2" ContentKey="topmsgcontactus" runat="server" /></div>
                </div>
                <div class="card-body">
                    <b-alert show variant="success">Message Successfully Sent</b-alert>

                    <h6 class="card-subtitle text-muted">Contact Name</h6>
                    <div class="mb-3">{{form.ContactName}}</div>

                    <h6 class="card-subtitle text-muted">Email Address</h6>
                    <div class="mb-3">{{form.EmailAddress}}</div>

                    <h6 class="card-subtitle text-muted">Message</h6>
                    <div class="preline">{{form.Message}}</div>
                    <hr />
                    <div class="text-right"><a class="btn btn-secondary mr-3" href="/contactus">Send Another Message</a><a class="btn btn-primary" href="/">Home</a></div>

                </div>
            </section>

        </div>
    </div>
</template>

<script>
    // @ is an alias to /src
    //import HelloWorld from '@/components/HelloWorld.vue'
    //import SitecodeImage from '@/components/SitecodeImage';
    import axios from 'axios'
    import ContentDb from '@/components/ContentDb';


    export default {
        name: 'ContactUs',
        components: { ContentDb },
        data() {
            return {
                form: { ContactName: '', EmailAddress: '', Message: '', AcceptTerms: false },
                formErrors: {},
                formState: {},
                isValidated: true,
                alertMessageVisible: false,
                alertMessage: '',
                alertVariant: 'success', //'danger', 'warning'
                nullValue: null,
                falseValue: false,
                isDone: false
            }
        },
        computed: {
            isLoggedIn() {
                return this.$store.state.isLoggedIn
            },
            isValid() {
                //if (w == '') return false;
                return true;
            }
        },
        mounted() {
            this.formStateUpdate(true);
        },
        methods: {
            formStateUpdate(init) {

                var fields = Object.getOwnPropertyNames(this.form);
                for (let i = 0; i < fields.length; i++) {
                    let field = fields[i];
                    if (init) {
                        this.formState[field] = null;
                        this.formErrors = {}; //reset formErrors object
                    } else if (typeof this.formErrors[field] === 'undefined' || this.formErrors[field] === '') {
                        this.formState[field] = true;
                        console.log("field: " + field + " " + JSON.stringify(this.formState[field]));
                    } else {
                        this.formState[field] = false;
                        console.log("field: " + field + " false");
                    }
                }
            },

            onSubmit() {
                this.alertMessageVisible = false;


                console.log("Form: " + JSON.stringify(this.form));
                this.formStateUpdate(true);

                return axios
                    .post(
                        '/api/site/contactussend',
                        this.form
                    )
                    .then(({ data }) => {
                        console.log('Successful: ' + data);
                        this.isDone = true;
                        //this.formStateUpdate(false);
                    })
                    .catch((error) => {
                        this.alertMessage = error.response.data.message ? error.response.data.message : ('An error has occurred: ' + JSON.stringify(error.response.data));
                        this.alertMessageVisible = true;
                        this.alertVariant = 'warning';
                        if (error.response.data.formErrors) {
                            this.formErrors = error.response.data.formErrors;
                            this.formStateUpdate(false);
                        }
                        //this.isValidated = true;
                    })
                    ;
            }
        }

    }
</script>
