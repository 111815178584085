<template v-if="isLoggedIn">
    <div class="container page">

        <div class="card card-default" style="min-width:420px">
            <div class="card-header"><h3>Management Statement</h3></div>
            <div class="card-body">

                <table class="table table-hover table-condensed">
                    <tr><th>Date</th><th>Description</th><th class="text-right">Debit</th><th class="text-right">Credit</th></tr>
                    <tr v-for="f in report" :key="f._rowNum">
                        <td>{{ f.transDate.sjToDate('d MMM yyyy HH:mm') }}</td>
                        <td>{{ f.transDescription }}</td>
                        <td class="text-right">{{ ( 0 > f.pointsValue ) ? f.pointsValue : '' }}</td>
                        <td class="text-right">{{ f.pointsValue >= 0 ? f.pointsValue : '' }}</td>
                    </tr>
                </table>
                <div v-if="isLoading"><i>Loading...</i></div>


                <div><b><label ID="lblMessage" runat="server" Text="" ForeColor="#ff0000" /></b></div>
            </div>
        </div>



    </div>

   
</template>

<script>
    // @ is an alias to /src
    import axios from 'axios'
    import sjData from '@/sjUtilData'


    export default {
		name: 'ManagementStatement',
        data() {
            return {
                monthList: [],
                selMonth: null,
                isAll: false,
                report: [],
                isLoading: false
            }
        },
        computed: {
            member() {
                return this.$store.state.memberStore.member
            },

            isLoggedIn() {
                return this.$store.state.isLoggedIn
            },
        },

        created() {
            this.loadReport();
        },
        mounted() {
            //axios.post('/api/member/memberget', {})
            //	.then(({ data }) => {
            //		//this.roles = data.roles;
            //		//this.noms = new sjData(data.noms).data;
            //		//this.displayPart = 1;
            //	})
            //	.catch((error) => {
            //		if (error) {
            //			this.msgBox(error.response.data.message);
            //		}
            //	})
        },
		watch: {
			
		},

        methods: {
            loadReport() {
                this.isLoading = true;
                this.report = [];
                axios.post('/api/report/managementstatement', {  })
                    .then(({ data }) => {
						this.isLoading = false;

                        this.report = new sjData(data.report).data;
                    })
                    .catch((error) => {
						this.isLoading = false;
                        if (error) {
                            this.msgBox(error.response.data.message);
                        }
                    })
            },


            //*** formStatusUpdate - used to display form errors.
            //Ensure this.form contains the list of fields.
            //Call  formStatusUpdate(true) to initialise/clear the form errors.
            //formObject is the object that contains the following objects:  .form, .formState, .formErrors
            formSetState(formObj, resetState, resetForm) {
                if (typeof resetState === 'undefined') resetState = false;
                if (typeof resetForm === 'undefined') resetForm = false;
                if (typeof formObj.init === 'undefined') formObj.init = { ...formObj.form }; //Initialise .formInit object to be a copy of .form object.
                if (typeof formObj.errors === 'undefined') this.$set(formObj, 'errors', {}); //use this.$set  to make errors object reactive
                if (typeof formObj.state === 'undefined') this.$set(formObj, 'state', {});   //use this.$set  to make state object reactive

                if (resetState || resetForm) {
                    if (resetForm) formObj.form = { ...formObj.init }; //copy .init to .form

                    formObj.errors = {}; //reset formErrors object
                    formObj.state = {};
                }

                //update formState
                var fields = Object.getOwnPropertyNames(formObj.form);
                for (let i = 0; i < fields.length; i++) {
                    let field = fields[i];
                    if (resetState) {
                        formObj.state[field] = null;
                    } else if (typeof formObj.errors[field] === 'undefined' || formObj.errors[field] === '') {
                        formObj.state[field] = true;
                    } else {
                        formObj.state[field] = false;
                    }
                }
            },
            msgBox(msg, title) {
                this.$bvModal.msgBoxOk(msg, { title: typeof title === 'undefined' ? 'Please note:' : title, centered: true })
            }


        }

    }
</script>
