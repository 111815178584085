<template v-if="isLoggedIn">
    <div class="container page">

        <div class="card card-default">
            <div class="card-header"><h3>Agent Commission Statements</h3></div>
            <div class="card-body">

                <div v-for="file in files" :key="file[0]">
                    <a target="_blank" :href="'/api/site/commissionstatementdownload?m=' + memnum + '&r=' + encodeURIComponent(file[0]) + '&s=' + encodeURIComponent(file[1])">{{ file[1] }}</a>
                </div>

                <div v-if="isLoading"><i>Loading...</i></div>
                <div v-else-if="files.length===0"><i>You have no commission statements.</i></div>

                <div><b><label ID="lblMessage" runat="server" Text="" ForeColor="#ff0000" /></b></div>
            </div>
        </div>



    </div>

   
</template>

<script>
    // @ is an alias to /src
    import axios from 'axios'
   // import sjData from '@/sjUtilData'


    export default {
        name: 'Statement',
        data() {
            return {
                files: [],
                memnum: ''
            }
        },
        computed: {
            member() {
                return this.$store.state.memberStore.member
            },

            isLoggedIn() {
                return this.$store.state.isLoggedIn
            },
        },

        created() {
            this.loadReport();
        },
        mounted() {

        },
        watch: {

        },

        methods: {
            loadReport() {
                this.isLoading = true;
                this.files = [];
				axios.post('/api/site/bayportcommissionstatements', {})
                    .then(({ data }) => {
                        this.isLoading = false;
                        this.files = data.files;
                        this.memnum = data.memnum;
                    })
                    .catch((error) => {
                        this.isLoading = false;
                        if (error) {
                            this.msgBox(error.response.data.message);
                        }
                    })
            },

            msgBox(msg, title) {
                this.$bvModal.msgBoxOk(msg, { title: typeof title === 'undefined' ? 'Please note:' : title, centered: true })
            },


        }

    }
</script>
