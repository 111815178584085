<template>
    <span v-html="htmlContent"></span>
</template>

<script>
	import axios from 'axios'

	export default {
		name: 'contentDb',
		props: {
			contentKey: String,
			isCache: {
				type: Boolean,
				default: false
			}
		},
		data() {
			return {
				htmlContent: ''
			}
		},
		computed: {
			isLoggedIn() {
				return this.$store.state.isLoggedIn
			}
		},
		mounted() {
			this.loadContent(this.contentKey)
		},
		watch: {
			contentKey(toKey) { //, from
				this.loadContent(toKey)
			}
		},
		methods: {
			loadContent(toKey) {
				if (toKey === '') {
					this.htmlContent = 'Invalid Key';
					return;
				}
				axios.post('/api/site/getcontentdb', { contentKey: toKey, isCache: this.isCache })
					.then(({ data }) => {
						this.htmlContent = data.content;
						this.$emit('title-onset', data.title)
					})
					.catch((error) => {
						if (error) {
							this.htmlContent = 'Error retrieving content';
						}
					})

			}
		}
	}
</script>

