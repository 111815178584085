<template>
	<header v-if="!isLoginPage" class="topHead d-print-none">
		<div class="container-fluid">
			<div class="row">
				<div class="col-sm-9 col-xl-4 d-flex" style="padding-left:60px;">
					<router-link to="/">
						<img v-if="companyCode=='S158'" src="../assets/image/headLogoEnergy.png" class="img-fluid align-self-center pt-4 pb-3" />
						<img v-else src="../assets/image/headLogo.png" class="img-fluid align-self-center pt-4 pb-3" />
					</router-link>
				</div>
				<div class="py-3 col-sm-3 col-xl-8 d-flex align-items-center justify-content-end order-first order-sm-last headNav">
					<b-navbar toggleable="xl">
						<!--<b-navbar-brand href="#">
						<img alt="" src="../assets/image/topHeadLogoLeft.png" />
					</b-navbar-brand>-->

						<b-navbar-toggle class="ml-auto my-1" target="nav-collapse"></b-navbar-toggle>

						<b-collapse id="nav-collapse" is-nav>
							<b-navbar-nav v-if="isLoggedIn">
								<b-nav-item to="/"><b-icon icon="house-fill" aria-hidden="true"></b-icon></b-nav-item>
								<b-nav-item-dropdown text="My Profile" right>
									<b-dropdown-item to="/myprofile">My Details</b-dropdown-item>
									<!--<b-dropdown-item to="/getinvolved">Get Involved</b-dropdown-item>-->
									<!--<b-dropdown-item @click.prevent="$bvModal.show('modalHowItWorks')">How it Works</b-dropdown-item>-->
									<!--<b-dropdown-item @click.prevent="$bvModal.show('modalFAQ')">FAQs</b-dropdown-item>-->
									<b-dropdown-item to="/contactus">Contact Us</b-dropdown-item>
									<b-dropdown-divider v-if="isManager || isAdminReports"></b-dropdown-divider>
									<b-dropdown-header v-if="isManager || isAdminReports"><u>Management</u></b-dropdown-header>
									<b-dropdown-item v-if="isManager" to="/managementstatement">Management Statement</b-dropdown-item>
									<!--<b-dropdown-item v-if="isManager" to="/reportmanagement">Management Report</b-dropdown-item>
	<b-dropdown-item v-if="isManager" to="/nominationmanagement">Management Nominations Review</b-dropdown-item>
	<b-dropdown-item v-if="isAdminReports" to="/reportquarterlyawards">Quarterly Awards Report</b-dropdown-item>-->

								</b-nav-item-dropdown>

								<!--<b-nav-item to="/myprofile">My Profile</b-nav-item>
							<b-nav-item @click.prevent="$bvModal.show('modalHowItWorks')">How it Works</b-nav-item>
							<b-nav-item @click.prevent="$bvModal.show('modalFAQ')">FAQs</b-nav-item>-->

								<b-nav-item-dropdown text="My Rewards" right>
									<b-dropdown-item to="/rewards">Redeem</b-dropdown-item>
									<b-dropdown-item to="/rewards/basket">My Basket</b-dropdown-item>
									<b-dropdown-item to="/orders">My Orders</b-dropdown-item>
									<b-dropdown-item to="/statement">Statement</b-dropdown-item>
									<b-dropdown-item to="/cardstatement">Gift Card Statement</b-dropdown-item>
								</b-nav-item-dropdown>

								<b-nav-item to="/myrecognition">My Recognition</b-nav-item>
								<b-nav-item to="/rewardpayitforward">Pay It Forward</b-nav-item>

								<!--<b-nav-item-dropdown v-if="isManager" text="Management" right>
								<b-dropdown-item v-if="isManager" to="/managementstatement">Management Statement</b-dropdown-item>
								<b-dropdown-item v-if="isManager" to="/reportmanagement">Management Report</b-dropdown-item>
								<b-dropdown-item v-if="isManager" to="/nominationmanagement">Management Nominations Review</b-dropdown-item>
								<b-dropdown-item v-if="isAdminReports" to="/reportquarterlyawards">Quarterly Awards Report</b-dropdown-item>
							</b-nav-item-dropdown>-->
								<!--<b-nav-item to="/contactus">Contact Us</b-nav-item>-->
							</b-navbar-nav>

							<b-navbar-nav v-if="isLoggedIn" class="ml-auto">
								<b-nav-item to="/logout" class="navLogout">Log Out</b-nav-item>
							</b-navbar-nav>

							<b-navbar-nav v-if="!isLoggedIn" class="ml-auto">
								<!--<b-nav-item to="/">Home</b-nav-item>-->
								<!--<b-nav-item @click.prevent="$bvModal.show('modalHowItWorks')">How it Works</b-nav-item>
							<b-nav-item @click.prevent="$bvModal.show('modalFAQ')">FAQs</b-nav-item>-->
								<b-nav-item to="/"><b-icon icon="house-fill" aria-hidden="true"></b-icon> Home</b-nav-item>
							</b-navbar-nav>

							<!-- Right aligned nav items -->
							<!--
						<b-navbar-nav class="ml-auto">
						<b-nav-form>
							<b-form-input size="sm" class="mr-sm-2" placeholder="Search"></b-form-input>
							<b-button size="sm" class="my-2 my-sm-0" type="submit">Search</b-button>
						</b-nav-form>


						<b-nav-item-dropdown right>
							!-- Using 'button-content' slot --
							<template v-slot:button-content>
								<em>User</em>
							</template>
							<b-dropdown-item href="#">Profile</b-dropdown-item>
							<b-dropdown-item href="#">Sign Out</b-dropdown-item>
						</b-nav-item-dropdown>
						</b-navbar-nav>
						-->
						</b-collapse>
					</b-navbar>
				</div>
			</div>
		</div>
		<div class="headBg pt-5">
			<div class="container" v-if="isLoggedIn">
				<div class="row no-gutters">
					<div class="col-9 col-xl-8 my-5 my-lg-5">
						<div class="text-lg-left text-center headWelcome">Hello</div>
						<div class="text-lg-left text-center headName">{{ firstname }}</div>
					</div>

					<div class="col-3 col-xl-4 my-1 my-lg-2 text-left text-lg-left">
						<router-link to="/myprofile" title="Change profile picture or update details">
							<img id="imgProfilePic" :src="profilePicSrc" class="headprofilepic" />
						</router-link>
					</div>
				</div>
			</div>
			<div v-else style="min-height:100px;"></div>
		</div>

		<div class="container headPoints" v-if="isLoggedIn">
			<div class="row">
				<div class="col-6 col-xl-3 headPointsRedeem">{{ $store.state.memberStore.member.awardsPointsAvailable + ' ' + $store.state.memberStore.member.pointsName }}</div>
				<div v-if="isManager" class="col-6 col-xl-3 headPointsRecognise">{{ $store.state.memberStore.member.awardsPointsAvailableMng + ' ' + $store.state.memberStore.member.pointsName }}</div>
			</div>
			<div class="row">
				<div class="col-6 col-xl-3 headPointsSuffix">TO REDEEM</div>
				<div v-if="isManager" class="col-6 col-xl-3 headPointsSuffix">TO RECOGNISE</div>
			</div>
		</div>

	</header>
</template>

<script>
	//
	//import { mapState } from 'vuex'
	//import SitecodeImage from '@/components/SitecodeImage';
	export default {
		name: 'AppHeader',
		components: {
			//'sitecode-image': SitecodeImage
		},
		computed: {
			windowWidth() {
				return this.$store.state.windowWidth
			},
			isLoggedIn() {
				return this.$store.state.isLoggedIn
			},
			rootFolder() {
				return process.env.BASE_URL
			},
			isLoginPage() {
				return this.$route.name === "Hello";
			},
			//        imgUrl() {
			////return '../assets/image/topHeadLogo_' + this.$store.state.siteTemplate + '.png';
			//return require('../assets/image/topHeadLogo_' + this.$store.state.siteTemplate + '.png');
			//        },
			firstname() {
				return this.$store.state.memberStore.member.firstname
			},//,
			profilePicSrc() {
				return '/profilepic/' + this.$store.state.memberStore.member.picId + this.$store.state.memberStore.profilepicSfx
			},//,
			//...mapState({
			//    member: state => state.memberStore.member
			//})
			awardsPointsAvailable() {
				return this.$store.state.memberStore.member.awardsPointsAvailable
			},
			awardsPointsAvailable1() {
				return this.$store.state.memberStore.member.awardsPointsAvailable1
			},
			awardsPointsAvailableMng() {
				return this.$store.state.memberStore.member.awardsPointsAvailableMng
			},
			isAdminReports() {
				return this.$store.state.memberStore.member.isAdminReports
			},
			isManager() {
				return this.$store.state.memberStore.member.isManager
			},
			countryCode() {
				return this.$store.state.memberStore.member.countryCode
			},
			companyCode() {
				return this.$store.state.memberStore.member.companyCode
			},
		},
		mounted() {
			this.$nextTick(() => {
				this.handleResize();
			});
		},
		updated() {
			this.$nextTick(() => {
				this.handleResize();
			});
		},
		watch: {
			windowWidth() { //newWidth, oldWidth
				//console.log(`width changed to ${newWidth} from ${oldWidth}`);
				this.handleResize();
			}
		},
		methods: {
			handleResize: function () {
				if (this.$store.state.siteTemplate != 'santam') return; //Only Santam needs sizing
				if (!this.$refs.topHeadLogoLeft) {
					console.log('handleResize: cannot find topHeadLogoLeft');
					setTimeout(this.handleResize, 250)
					return;
				}

				let h = this.$refs.topHeadLogoLeft.firstElementChild.offsetHeight; //$('.homeicon1').innerHeight();
				let w = this.$refs.topHeadLogoLeft.firstElementChild.offsetWidth; //$('.homeicon1').innerWidth();

				if (w < 3 || h < 3) {
					//console.log('handleResize: topHeadLogoLeft w or h = 0');
					setTimeout(this.handleResize, 250)
					return;
				}

				let sw = w / 354;
				let sh = h / 298;

				console.log('handleResize: topHeadLogoLeft w: ' + sw + ', h: ' + sh);
				this.$refs.imgProfilePic.style.left = (sw * 122) + "px";
				this.$refs.imgProfilePic.style.top = (sh * 68) + "px";
				this.$refs.imgProfilePic.style.width = (sw * 222) + "px";
				this.$refs.imgProfilePic.style.height = (sh * 222) + "px";
			}
		}
	}
</script>

