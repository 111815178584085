//https://davidwalsh.name/javascript-debounce-function
//https://dev.to/monaye/refactor-davidwalsh-s-debounce-function-5afc
/*
            window.addEventListener('resize', sjDebounce(myEfficientFn, 250));

            function myEfficientFn() {
	            // All the taxing stuff you do
            }

*/

//const sjDebounce = (func, delay, immediate) => {
//    let timerId;
//    return (...args) => {
//        console.log((new Date) + ' sjDebounce Called');

//        const boundFunc = func.bind(this, ...args);
//        clearTimeout(timerId);
//        if (immediate && !timerId) {
//            boundFunc();
//        }
//        const calleeFunc = immediate ? () => {
//            timerId = null
//        } : boundFunc;
//        timerId = setTimeout(calleeFunc, delay);
//    }
//}

//const sjDebounce = (func, delay) => {
//    let timerId;
//    return (...args) => {
//        const boundFunc = func.bind(this, ...args);
//        clearTimeout(timerId);
//        timerId = setTimeout(boundFunc, delay);
//    }
//}

//export default sjDebounce


export function sjDebounce(fn, delay) {
    var timeoutID = null
    return function () {
        clearTimeout(timeoutID)
        var args = arguments
        var that = this
        timeoutID = setTimeout(function () {
            fn.apply(that, args)
        }, delay)
    }
}