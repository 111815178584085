//*****************************************************/
// ver 2014-06-23
// CONTAINS:
// sjPrototypes
//		sjRemoveIntHash
//		sjReplaceAll
//		sjReplaceCRLFtoBR
//		sjRight
//		sjExists
//		sjHtmlEncode
//*****************************************************/

//To include cookies... http://www.quirksmode.org/js/cookies.html

/*
//Returns the type of an object. i.e. String,Boolean,Date,Number, etc  http://mrrena.blogspot.com/2012/05/javascript-better-typeof-accurately.html
const realTypeOf = function (obj) {
	return Object.prototype.toString.call(obj).slice(8, -1);
};
*/

function setupPrototypes() {

	///////////////////////////////////////////////
	// sjPrototypes
	///////////////////////////////////////////////



	String.prototype.sjRemoveIntHash = function () {
		let t = this;
		let p = t.indexOf(".");
		if (p > 0) {
			return t.substr(0, p);
		} else {
			return t;
		}
	}

	String.prototype.sjTrim = function () {
		return this.replace(/^\s+|\s+$/g, '');
	}

	String.prototype.sjReplaceAll = function (str1, str2) {
		return this.split(str1).join(str2);
		//http://dumpsite.com/forum/index.php?topic=4.msg29#msg29
		//return this.replace(new RegExp(str1.replace(/([\/\,\!\\\^\$\{\}\[\]\(\)\.\*\+\?\|\<\>\-\&])/g, "\\$&"), (ignore ? "gi" : "g")), (typeof (str2) == "string") ? str2.replace(/\$/g, "$$$$") : str2);
	};

	//String.prototype.sjReplaceAll = function (a, b) {
	//	var t = this;
	//	while (t.indexOf(a) != -1) {
	//		t = t.replace(a, b);
	//	}
	//	return t;
	//	//	return (this.replace(new RegExp(a, "g"), b));
	//}
	String.prototype.sjReplaceCRLFtoBR = function () { //replace CR and/or LF to BR
		return String(this).replace(/\r\n|\r|\n/g, '<br />')
		//var t = this;
		//return t.sjReplaceAll("\r\n", "<br />").sjReplaceAll("\r", "<br />").sjReplaceAll("\n", "<br />");
	}

	String.prototype.sjRight = function (n) {
		var str = this;
		if (n <= 0) {
			return "";
		} else if (n > String(str).length) {
			return str;
		} else {
			var iLen = String(str).length;
			return String(str).substring(iLen, iLen - n);
		}
	}
	Array.prototype.sjExists = function (value) { //Find value in Array
		for (var i = 0; i < this.length; i++) {
			if (this[i] === value) {
				return true;
			}
		}
		return false;
	};
	//Object.prototype.sjExistsName = function (nameToFind) { //Find a name in Object  i.e. var fruit={orange:5,apple:7,grape:3}; if (fruit.sjExistName('apple')) alert('found apple');   
	//	for (var name in obj) {
	//		if (name === nameToFind) return true;
	//	}
	//	return false;
	//};
	String.prototype.sjHtmlEncode = function () { //Also replaces CRLF to <BR />
		return String(this)
			.replace(/&/g, '&amp;')
			.replace(/"/g, '&quot;')
			.replace(/'/g, '&#39;')
			.replace(/</g, '&lt;')
			.replace(/>/g, '&gt;')
			.replace(/\r\n|\r|\n/g, '<br />');
	};

	String.prototype.sjReplaceAllButCharWithDash = function () {
		return String(this).replace(/[^a-z0-9]/gi, '-').replace(/^[-]+|[-]+$/, '').replace(/[-]{2,}/g, '-');
	};

	String.prototype.sjToDate = function(format) {
		if (this === null || this === '') return this;

		if (typeof format === 'undefined' || format == null ||format == '') {
			return new Date(String(this) + 'Z')
		} else {
			return new Date(String(this) + 'Z').sjFormat(format)
		}
	}

	Number.prototype.sjFormatMoney = function (format, showCRDR) { //format:  "sym | places | isSymSuffix | dot | thousandSep" i.e. "R |1|y|.| " //Note that spaces are defined also in the formatting!
		var n = this;
		if (n === null) n = 0;
		if (typeof format === "undefined" || format.length == 0) { format = "R|2|false|.| "; }
		var f = format.split('|');
		var l = f.length;

		var sym = (l <= 0 || f[0].length == 0) ? '' : f[0]; //Currency symbol. (symbol not shown if CRDR shown
		var places = parseInt((l <= 1 || isNaN(f[1].sjTrim())) ? 2 : f[1].sjTrim());//Number of decimal places
		var symsuffix = (l <= 2 || f[2].sjTrim().length == 0) ? '0' : f[2].sjTrim().toUpperCase(); //isSymSuffix  either 0 or 1,   Y or N,  y or n,   true or false
		var dot = (l <= 3 || f[3].length == 0) ? '.' : f[3]; //Dot symbol
		var thousandSep = (l <= 4 || f[4].length == 0) ? '' : f[4]; //Thousands separator character

		var isSymSuffix = symsuffix == "1" || symsuffix == "TRUE";

		if (typeof showCRDR === 'undefined') { showCRDR = ''; }
		var s1 = (n < 0 ? "-" : "");
		var s2 = '';
		if (showCRDR == 'CRDR') {
			s1 = '';
			s2 = (n < 0 ? " DR" : " CR");
			sym = '';
		} else if (showCRDR == '()') {
			s1 = (n < 0 ? "(" : "");
			s2 = (n < 0 ? ")" : "");
			sym = '';
		} else if (showCRDR == 'NOSYM') {
			sym = '';
		}
		let u = Math.abs(n).toFixed(places);
		let v = parseInt(u);
		let i = v.toString();
		let j = i.length > 3 ? i.length % 3 : 0;

		//let i = parseInt(n = Math.abs(+n || 0).toFixed(places)) + "";
		//let j = (j = i.length) > 3 ? j % 3 : 0;

		let res= (isSymSuffix ? '' : sym)
			+ s1
			+ (j!==0 ? i.substr(0, j) + thousandSep : "")
			+ i.substr(j).replace(/\B(?=(?=\d*)(\d{3})+(?!\d))/g, thousandSep)
			+ (places ? dot + Math.abs(Number(u) - v).toFixed(places).slice(2) : "")
			+ (isSymSuffix ? sym : '')
			+ s2;
		return res;
	};

	Date.prototype.sjFormat = function (format) {
		/* format:
	dd|MMM|yy|HH:mm:ss   i.e. .sjFormat('dd MMM yyyy h:mm tt')
		 */
		var d = this;
		var months = ["January", "February", "March", "April", "May", "June", "July", "August", "September", "October", "November", "December"];
		var days = ['Sunday', 'Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday'];
		var value = '';
		if (typeof format === "undefined" || format == null) format = "dd/MM/yyyy";
		var f = new Array();
		var s = '';
		var wasLastSpec = false;
		for (var i = 0; i < format.length; i++) {
			var c = format.substr(i, 1);
			var cu = c.toLowerCase();
			var isSpec = (cu == 'd' || cu == 'm' || cu == 'y' || cu == 'h' || cu == 's' || cu == 't');
			if (!isSpec && wasLastSpec || !wasLastSpec && isSpec) {
				f[f.length] = s;
				s = '';
			}
			wasLastSpec = isSpec;
			s += c;
		}
		f[f.length] = s;

		for (let i = 0; i < f.length; i++) {
			let c = f[i].substr(0, 1); //the input bit
			let l = f[i].length;
			let o = ''; //The output bit
			let mm = d.getMonth() + 1;
			let hh = d.getHours();
			if ((c == 'd' || c == 'D') && l < 3) o = d.getDate().toString();
			else if (c == 'M' && l < 3) o = mm.toString();
			else if (c == 'M' && l == 3) o = months[mm-1].slice(0, 3);
			else if (c == 'M' && l > 3) o = months[mm-1];
			else if (c == 'y' || c == 'Y') o = d.getFullYear().toString();
			else if (c == 'H') o = hh.toString(); //24 hour clock
			else if (c == 'h') o = ((hh + 24) % 12 || 12).toString(); //12 hour clock
			else if (c == 'm') o = d.getMinutes().toString();
			else if (c == 's') o = d.getSeconds().toString();
			else if (c == 't') o = hh > 11 ? "pm" : "am";
			else if ((c == 'd' || c == 'D') && l > 2) o = days[d.getDay()];
			else c = 'X'; //any other character

			if (c != 'X') {
				if ((c == 't' && l == 1) || (c == 'd' && l == 3) || (c == 'y' && l > 2)) { //truncate
					o = o.slice(0, l);
				} else if (l == 2) { //pad on left
					o = ("00" + o).slice(-2)
				}
			} else {
				o = f[i];
			}

			value += o;
		}
		return value;

	}
}

export default setupPrototypes
