<template>
    <div>
        <template v-if="isCardUnallocated">
            <a href="#" @click.prevent="$bvModal.show('modalCardAllocate')">Activate New Gift Card</a>
        </template>
        <template v-else>
            <div>
                <span>{{ tutukaCard.cardNum }}</span>
            </div>
            <div>
                <span>{{ tutukaCard.msg }}</span>
                <button v-if="tutukaCard.status==='Active'" class="btn btn-primary btn-sm ml-5" @click="$bvModal.show('modalCardStop')">Stop  Card</button>
                <button v-if="tutukaCard.status==='Stopped'" class="btn btn-primary btn-sm ml-5" @click="$bvModal.show('modalCardUnstop')">Un-Stop Card</button>
                <button v-if="!isCardUnallocated" class="btn btn-primary btn-sm ml-3" @click="$bvModal.show('modalCardAllocate')">Replace Card</button>
            </div>
        </template>

        <!-- Modal - Allocate New Gift Card -->
        <b-modal id="modalCardAllocate" :title="isCardUnallocated?'Allocate New Gift Card':'Replace Existing Gift Card'" hide-footer size="xl" no-close-on-backdrop>
            <b-form @submit.prevent="onCardAllocate" novalidate>
                <b-form-group id="idnumberGrp" label-for="idnumberTxt" label="South African ID Number:" description="Your ID number is required for FICA" :invalid-feedback="fCardAllocate.errors.idnumber">
                    <b-form-input id="idnumberTxt" v-model="fCardAllocate.form.idnumber" :state="fCardAllocate.state.idnumber" placeholder="" maxlength="80" />
                </b-form-group>
                <b-form-group id="cardnumGrp" label-for="cardnumTxt" label="Gift Card Number:" :description="isCardUnallocated?'Enter the 16 digit number on your gift card':'Your old card will no longer be valid'" :invalid-feedback="fCardAllocate.errors.cardnum">
                    <b-form-input id="cardnumTxt" v-model="fCardAllocate.form.cardnum" :state="fCardAllocate.state.cardnum" placeholder="" maxlength="80" />
                </b-form-group>
                <hr />
                <div class="text-right">
                    <button type="button" class="btn btn-link" @click="$bvModal.hide('modalCardAllocate')">Cancel</button>
                    <button type="submit" class="btn btn-primary ml-3">Submit</button>
                </div>
            </b-form>
        </b-modal>


        <!-- Modal - Stop Card -->
        <b-modal id="modalCardStop" title="Stop Gift Card" hide-footer size="xl" no-close-on-backdrop>
            <b-form @submit.prevent="onCardStop" novalidate>
                This will block your gift card and prevent unauthorised use.
                <hr />
                <b-form-group id="reasonGrp" label-for="reasonTxt" label="Reason:" description="" :invalid-feedback="fCardAllocate.errors.reason">
                    <b-form-select id="reasonTxt" v-model="fCardStop.form.reason" :state="fCardAllocate.state.reason">
                        <b-form-select-option :value="null" disabled>-- Select a reason for stopping card --</b-form-select-option>
                        <b-form-select-option value="1">Card has been lost</b-form-select-option>
                        <b-form-select-option value="2">Card has been stolen</b-form-select-option>
                    </b-form-select>
                </b-form-group>
                <hr />
                <div class="text-right">
                    <button type="button" class="btn btn-link" @click="$bvModal.hide('modalCardStop')">Cancel</button>
                    <button type="submit" class="btn btn-primary ml-3">Stop Card</button>
                </div>
            </b-form>
        </b-modal>

        <!-- Modal - UnStop Card -->
        <b-modal id="modalCardUnstop" title="Remove 'Stop' from Gift Card" hide-footer size="xl" no-close-on-backdrop>
            This will remove the "Stop" and re-activate your gift card.
            <hr />
            <div class="text-right">
                <button type="button" class="btn btn-link" @click="$bvModal.hide('modalCardUnstop')">Cancel</button>
                <button type="button" class="btn btn-primary ml-3" @click="onCardUnstop()">Un-Stop card</button>
            </div>
        </b-modal>
    </div>
</template>

<script>
	// @ is an alias to /src
	import axios from 'axios'
    export default {
        name: 'GiftCard',
        data() {
            return {
                fCardAllocate: {
                    form: { idnumber: '', cardnum: '', isSwop: false }
                },
                fCardStop: {
                    form: { reason: null }
                },
                tutukaCard: {
					isCardAllocated: false,
                    status: 'Loading',
                    msg: '',
                    cardNum: ''
                }
            }
        },
        created() {
            this.formSetState(this.fCardAllocate, true, true) //formObj, resetState, resetForm
            this.formSetState(this.fCardStop, true, true) //formObj, resetState, resetForm

        },
        mounted() {
            this.doGetCardStatus();
        },
        computed: {
            isCardUnallocated() {
                return this.tutukaCard.status === 'NotAllocated';
			}
		},
        methods: {
			formSetState(formObj, resetState, resetForm) {
				if (typeof resetState === 'undefined') resetState = false;
				if (typeof resetForm === 'undefined') resetForm = false;
				if (typeof formObj.init === 'undefined') formObj.init = { ...formObj.form }; //Initialise .formInit object to be a copy of .form object.
				if (typeof formObj.errors === 'undefined') this.$set(formObj, 'errors', {}); //use this.$set  to make errors object reactive
				if (typeof formObj.state === 'undefined') this.$set(formObj, 'state', {});   //use this.$set  to make state object reactive

				if (resetState || resetForm) {
					if (resetForm) formObj.form = { ...formObj.init }; //copy .init to .form

					formObj.errors = {}; //reset formErrors object
					formObj.state = {};
				}

				//update formState
				var fields = Object.getOwnPropertyNames(formObj.form);
				for (let i = 0; i < fields.length; i++) {
					let field = fields[i];
					if (resetState) {
						formObj.state[field] = null;
					} else if (typeof formObj.errors[field] === 'undefined' || formObj.errors[field] === '') {
						formObj.state[field] = true;
					} else {
						formObj.state[field] = false;
					}
				}
			},
			msgBox(msg, title) {
				this.$bvModal.msgBoxOk(msg, { title: typeof title === 'undefined' ? 'Please note:' : title, centered: true })
			},
            doGetCardStatus() {
                axios.post('/api/giftcardtk/getcardstatus', {})
                    .then(({ data }) => {
                        this.tutukaCard = {
                            cardNum: data.cardNum,
                            status: data.status,
                            msg: data.msg
                        }
                    })
                    .catch((error) => {
                        if (error) {
                            this.msgBox('Error retrieving card data');
                        }
                    })
            },
            onCardStop() {

                this.formSetState(this.fCardStop, true);//formObj, resetState, resetForm

                return axios
                    .post(
                        '/api/giftcardtk/stopcard',
                        this.fCardStop.form
                    )
                    .then(({ data }) => {
                        this.$bvModal.hide('modalCardStop');
                        this.tutukaCard.status = 'Stopped';
                        this.tutukaCard.msg = data.msg;
                        this.msgBox(data.msg);
                    })
                    .catch((error) => {
                        if (error && error.response) {
                            this.msgBox(error.response.data.message ? error.response.data.message : ('An error has occurred: ' + JSON.stringify(error.response.data)));
                            if (error.response.data.formErrors) {
                                this.fProfile.errors = error.response.data.formErrors;
                                this.formSetState(this.fProfile);
                            }
                        } else {
                            this.msgBox('Unexpected Error');
                        }
                    });
            },
            onCardUnstop() {
                return axios
                    .post(
                        '/api/giftcardtk/unstopcard', {}
                    )
                    .then(({ data }) => {
                        this.$bvModal.hide('modalCardUnstop');
                        this.tutukaCard.status = 'Active';
                        this.tutukaCard.msg = data.msg;
                        this.msgBox(data.msg);
                    })
                    .catch((error) => {
                        if (error && error.response) {
                            this.msgBox(error.response.data.message ? error.response.data.message : ('An error has occurred: ' + JSON.stringify(error.response.data)));
                        } else {
                            this.msgBox('Unexpected Error');
                        }
                    });
            },
            onCardAllocate() {
                this.fCardAllocate.form.isSwop = !this.isCardUnallocated;
				this.formSetState(this.fCardAllocate, true);//formObj, resetState, resetForm

				return axios
					.post(
						'/api/giftcardtk/activatecard',
						this.fCardAllocate.form
					)
                    .then(({ data }) => {
						this.$bvModal.hide('modalCardAllocate');
                        this.tutukaCard.cardnum = '';
                        this.tutukaCard.msg = data.result;
                        this.msgBox(data.result);
                        this.doGetCardStatus();
					})
					.catch((error) => {
						if (error && error.response) {
							this.msgBox(error.response.data.message ? error.response.data.message : ('An error has occurred: ' + JSON.stringify(error.response.data)));
							if (error.response.data.formErrors) {
								this.fProfile.errors = error.response.data.formErrors;
								this.formSetState(this.fProfile);
							}
						} else {
							this.msgBox('Unexpected Error');
						}
					});
			},
        }
    }
</script>

<style scoped>
</style>
